import { useQuery } from 'react-query';
import { QueryKey } from 'src/config/queryKey';
import { getRandomSpeakers } from 'src/common/services/speaker';
import { randomSpeakersLimit } from 'src/config/limits';

export const useRandomSpeakersQuery = (limit = randomSpeakersLimit) =>
  useQuery([QueryKey.RandomSpeakers, limit], () => getRandomSpeakers(limit), {
    refetchOnMount: true,
    staleTime: 10,
  });
