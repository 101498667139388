import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import SwiperCore, { Navigation, Scrollbar, SwiperOptions } from 'swiper';
import { Swiper } from 'swiper/react';
import ArrowIcon from 'src/assets/icons/ArrowIcon';
import { StyleProps } from 'src/lib/styleProps';
import styled from 'styled-components';
import { CleanButton } from 'src/common/components/Button';
import { colors } from 'src/config/colors';
import { layers } from 'src/config/layers';
import { transparentize } from 'polished';
import { mergeDeepRight } from 'ramda';

export const NavigationButton = styled(CleanButton)`
  color: ${colors.red};
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: ${layers.contentTop};

  &:disabled {
    color: ${colors.blue};
    cursor: default;
  }
`;

const NextButton = styled(NavigationButton)`
  right: -1.4375rem;
`;

const PrevButton = styled(NavigationButton)`
  left: -1.4375rem;
`;

const StyledSwiper = styled(Swiper)`
  margin: 0 -1.5rem;
  padding: 2.375rem 1.5rem;

  &::after,
  &::before {
    bottom: 0;
    content: '';
    position: absolute;
    top: 0;
    width: 1.5rem;
    z-index: ${layers.contentBottom};
  }

  &::after {
    background: linear-gradient(to left, ${colors.white}, ${transparentize(1, colors.white)});
    right: -0.0625rem;
  }

  &::before {
    background: linear-gradient(to right, ${colors.white}, ${transparentize(1, colors.white)});
    left: -0.0625rem;
  }

  .swiper-scrollbar {
    background: ${colors.darkGrey};
    border-radius: 6.25rem;
    bottom: 0.5rem;
    left: 50%;
    max-width: 100%;
    transform: translateX(-50%);
    width: 19.375rem;
  }

  .swiper-scrollbar-drag {
    background: ${colors.blue};
    border-radius: 6.25rem;
  }

  .swiper-slide {
    backface-visibility: hidden;
  }

  .swiper-wrapper {
    align-items: center;
  }
`;

const SwiperContainer = styled.div`
  position: relative;
`;

export interface CourseCardSwiperProps {
  options?: SwiperOptions;
}

export const CourseCardSwiper: FC<CourseCardSwiperProps & StyleProps> = ({
  children,
  className,
  options,
  style,
}) => {
  const [, setActiveSlide] = useState<HTMLButtonElement | null>(null);
  const [nextButton, setNextButton] = useState<HTMLButtonElement | null>(null);
  const [prevButton, setPrevButton] = useState<HTMLButtonElement | null>(null);
  const [sliderInstance, setSliderInstance] = useState<SwiperCore | undefined>();

  const slideChangeHandler = useCallback(
    (swiper) => setActiveSlide(swiper.realIndex),
    [setActiveSlide],
  );

  const swiperOptions = useMemo(
    () => ({
      ...(mergeDeepRight(
        {
          freeMode: {
            momentumRatio: 0.2,
            momentumVelocityRatio: 0.2,
            sticky: true,
          },
          navigation: {
            nextEl: nextButton ?? undefined,
            prevEl: prevButton ?? undefined,
          },
          scrollbar: {
            dragSize: 57,
            draggable: false,
          },
          spaceBetween: 24,
          onSlideChange: slideChangeHandler,
          onSwiper: setSliderInstance,
        },
        options || {},
      ) as SwiperOptions),
      modules: [Navigation, Scrollbar, ...(options?.modules ?? [])],
    }),
    [nextButton, options, prevButton, slideChangeHandler, sliderInstance],
  );

  useEffect(() => {
    sliderInstance?.navigation?.init();
  }, [sliderInstance, prevButton, nextButton]);

  return (
    <SwiperContainer className={className} style={style}>
      <StyledSwiper {...swiperOptions}>{children}</StyledSwiper>

      <PrevButton disabled={sliderInstance?.isBeginning} ref={setPrevButton}>
        <ArrowIcon direction="left" size={18} />
      </PrevButton>

      <NextButton disabled={sliderInstance?.isEnd} ref={setNextButton}>
        <ArrowIcon direction="right" size={18} />
      </NextButton>
    </SwiperContainer>
  );
};
