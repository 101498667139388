import React, { FC } from 'react';
import styled, { css, ThemeProvider } from 'styled-components';
import { SectionTitle } from 'src/common/components/SectionTitle';
import { typography } from 'src/config/typography';
import { range } from 'ramda';
import { SwiperSlide } from 'swiper/react';
import { CourseCardSwiper, NavigationButton } from 'src/common/components/CourseCardSwiper';
import { media } from 'src/config/breakpoints';
import { SpeakerCard } from 'src/common/components/SpeakerCard';
import { layers } from 'src/config/layers';
import { useRandomSpeakersQuery } from 'src/common/hooks/useRandomSpeakersQuery';
import { SpeakerCardSkeleton } from 'src/common/components/SpeakerCardSkeleton';
import { FreeMode, SwiperOptions } from 'swiper';

const Card = styled.div`
  width: 13.25rem;

  ${media.w.min.px992(css`
    width: auto;
  `)};
`;

const Container = styled.div`
  padding: 2.875rem 0 1.875rem;
  text-align: center;

  ${media.w.min.px768(css`
    padding: 5rem 0 2.5rem;
  `)};

  ${media.w.min.px1200(css`
    padding: 3.125rem 1.5rem 2.5rem;
    text-align: left;
  `)};

  ${({ theme }) =>
    theme.layoutBox &&
    css`
      padding: 0 0.75rem 5rem;

      ${media.w.min.px768(css`
        padding: 0 2.625rem 4.75rem;
        text-align: left;
      `)};

      ${media.w.min.px1200(css`
        padding: 0 1.5rem 3.875rem;
      `)};
    `}
`;

const Header = styled.div`
  ${media.w.min.px768(css`
    margin: 0 auto;
    max-width: 21.875rem;
  `)};

  ${media.w.min.px1200(css`
    margin: 0;
    max-width: 23.125rem;
    padding-left: 1.25rem;
  `)};

  ${({ theme }) =>
    theme.layoutBox &&
    css`
      padding: 0 0.75rem;
      ${media.w.min.px768(css`
        margin: 0;
      `)};
    `}
`;

const StyledCourseCardSwiper = styled(CourseCardSwiper)`
  ${NavigationButton} {
    ${media.w.max.px1200(css`
      display: none;
    `)};
  }

  .swiper-scrollbar {
    ${media.w.min.px1200(css`
      display: none;
    `)};
  }

  .swiper-slide {
    width: auto;

    ${media.w.between.px992.px1200(css`
      width: 13.25rem;
    `)}
  }

  ${({ theme }) =>
    theme.layoutBox &&
    css`
      padding: 0 0.75rem;

      ${NavigationButton} {
        ${media.w.max.px768(css`
          display: none;
        `)};
        ${media.w.min.px1200(css`
          margin: 10px;
        `)};
      }

      ${media.w.min.px1200(css`
        padding: 0 0.9375rem;
      `)};
      ${media.w.between.px768.px1200(css`
        padding: 0;
      `)};
    `}
`;

const StyledSectionTitle = styled(SectionTitle)`
  margin-bottom: 0.9375rem;

  ${media.w.min.px768(css`
    margin-bottom: 1.25rem;
  `)};
`;

const Text = styled.div`
  margin-bottom: -0.5rem;
  position: relative;
  z-index: ${layers.contentBottom};
`;

const Title = styled.div`
  font-family: ${typography.fontFamilyHeaders};
  font-size: 1.5rem;
  line-height: 1.875rem;
  margin-bottom: 0.9375rem;

  ${media.w.min.px768(css`
    margin-bottom: 0.625rem;
  `)};
`;

const getSwiperOptions: (layoutBox: boolean) => SwiperOptions = (layoutBox) => ({
  breakpoints: {
    1200: {
      centeredSlides: false,
      centeredSlidesBounds: false,
      freeMode: {
        enabled: false,
      },
      slidesPerView: 3,
    },
  },
  centeredSlides: layoutBox,
  centeredSlidesBounds: true,
  freeMode: {
    enabled: true,
  },
  modules: [FreeMode],
  slidesPerView: 'auto',
});

interface SpeakersProps {
  layoutBox?: boolean;
}

export const Speakers: FC<SpeakersProps> = ({ layoutBox }) => {
  const swiperOptions = getSwiperOptions(layoutBox ?? false);
  const { data } = useRandomSpeakersQuery();

  if (data && data.items.length === 0) {
    return null;
  }

  return (
    <ThemeProvider theme={{ layoutBox: layoutBox ?? false }}>
      <Container>
        <Header>
          <StyledSectionTitle>Nasi eksperci</StyledSectionTitle>

          <Title>Poznaj naszych wykładowców</Title>

          <Text>
            Ich pasją jest dzielenie się swoją wiedzą, umiejętnościami oraz historiami
            o&nbsp;sukcesach i&nbsp;porażkach - poznaj ich osobiście
          </Text>
        </Header>

        <StyledCourseCardSwiper options={swiperOptions}>
          {data
            ? data.items.map((item, index) => (
                <SwiperSlide key={index}>
                  <Card>
                    <SpeakerCard speaker={item} />
                  </Card>
                </SwiperSlide>
              ))
            : range(0, 5).map((item, index) => (
                <SwiperSlide key={index}>
                  <Card>
                    <SpeakerCardSkeleton />
                  </Card>
                </SwiperSlide>
              ))}
        </StyledCourseCardSwiper>
      </Container>
    </ThemeProvider>
  );
};
