import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { authActions } from 'src/state/auth/authActions';
import { redirectUrlSelector } from 'src/state/auth/authSelectors';
import { isSome } from 'fp-ts/lib/Option';

export const useResetRedirectUrl = (enabled = true) => {
  const dispatch = useDispatch();
  const redirectUrl = useSelector(redirectUrlSelector);

  useEffect(() => {
    if (enabled && isSome(redirectUrl)) {
      dispatch(authActions.setRedirectUrl(null));
    }
  }, [enabled]);
};
