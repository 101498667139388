import React, { FC } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { colors, shadows } from 'src/config/colors';
import SmallCtaIcon from 'src/assets/icons/SmallCtaIcon';
import { Speaker } from 'src/common/models/speaker';
import { typography } from 'src/config/typography';
import { textOverflow } from 'src/common/styles/typography';
import { speakerFullName } from 'src/common/services/speaker';
import { rem } from 'polished';
import { GatsbyLink } from 'src/common/migration/GatsbyLink';
import { getSpeakersItemPageRoute } from 'src/speakers/routes';
import Image from 'next/image';

const Bio = styled.div`
  color: ${colors.blue};
  margin: 1.6875rem auto 0 auto;
  max-width: 11.375rem;

  ${textOverflow(4)}
`;

const Box = styled.div`
  background: ${colors.white};
  border-radius: 0.375rem;
  box-shadow: ${shadows.drop1};
  padding: 1.625rem 0.9375rem;
  position: relative;
  text-align: center;
  transition: all 0.2s ease;
  width: 100%;
`;

const Company = styled.div`
  color: ${colors.blue};

  ${textOverflow(1)}
`;

const ImageContainer = styled.div`
  height: ${({ theme }) => (theme.mobileImageBig ? rem(130) : rem(100))};
  margin: 0 auto 1.6875rem;
  position: relative;
  width: ${({ theme }) => (theme.mobileImageBig ? rem(130) : rem(100))};
`;

const Name = styled.div`
  color: ${colors.pfp3bis};
  font-family: ${typography.fontFamilyHeaders};
  font-size: 1rem;
  line-height: 1.375rem;
  margin-top: auto;

  ${textOverflow(1)}
`;

const StyledImage = styled(Image)`
  border-radius: 50%;
  object-fit: contain;
`;

const StyledSmallCtaIcon = styled(SmallCtaIcon)`
  bottom: 0.625rem;
  color: ${colors.red};
  opacity: 0;
  position: absolute;
  right: 0.625rem;
  transition: opacity 0.2s ease;
`;

const StyledLink = styled(GatsbyLink)`
  display: flex;
  width: 100%;
  height: ${({ theme }) => (theme.mobileImageBig ? 'auto' : '19.5rem')};
  text-decoration: none;

  &:hover {
    ${Box} {
      padding-bottom: 2.375rem;
    }

    ${StyledSmallCtaIcon} {
      opacity: ${({ theme }) => (theme.featured ? 0 : 1)};
    }
  }
`;

export interface SpeakerCardProps {
  speaker: Speaker;
  mobileImageBig?: boolean;
}

export const SpeakerCard: FC<SpeakerCardProps> = ({ speaker, mobileImageBig }) => (
  <ThemeProvider theme={{ mobileImageBig }}>
    <StyledLink to={getSpeakersItemPageRoute(String(speaker.id))}>
      <Box>
        <ImageContainer>
          {speaker.photo != null && (
            <StyledImage alt={speakerFullName(speaker)} layout="fill" src={speaker.photo.url} />
          )}
        </ImageContainer>

        <Name>{speakerFullName(speaker)}</Name>
        <Company>{speaker.company}</Company>

        <Bio>{speaker.bio}</Bio>

        <StyledSmallCtaIcon size={18} />
      </Box>
    </StyledLink>
  </ThemeProvider>
);
