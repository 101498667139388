import HomepageHowItWorksSvg from 'src/assets/images/how-it-works-board.svg';
import styled, { css } from 'styled-components';
import { media } from 'src/config/breakpoints';

export const HomepageHowItWorksImage = styled(HomepageHowItWorksSvg)`
  height: 11.375rem;

  ${media.w.min.px768(css`
    height: 14.875rem;
  `)};
`;
