import React, { FC } from 'react';
import styled, { css, ThemeProvider } from 'styled-components';
import { SectionTitle } from 'src/common/components/SectionTitle';
import { Markdown } from 'src/common/components/Markdown';
import { HomepageHowItWorksImage } from 'src/common/images/HomepageHowItWorksImage';
import { Button } from 'src/common/components/Button';
import { typography } from 'src/config/typography';
import { colors, shadows } from 'src/config/colors';
import { media } from 'src/config/breakpoints';
import homepageHowItWorksLine from 'src/assets/images/homepage-how-it-works-line.url.svg';
import { layers } from 'src/config/layers';
import { StyleProps } from 'src/lib/styleProps';
import { GatsbyLink } from 'src/common/migration/GatsbyLink';
import { SearchRoute } from 'src/search/routes';

const ButtonContainer = styled.div`
  text-align: center;

  ${media.w.min.px768(css`
    grid-area: button;
    padding-right: 2rem;
    text-align: right;
  `)};
`;

const Container = styled.div`
  background: ${colors.white};
  box-shadow: ${shadows.drop1};
  border-radius: 0.5rem;
  margin: 1.875rem -1.5rem 0;
  padding: 3.125rem 1.5rem;

  ${media.w.min.px768(css`
    display: grid;
    grid-template-areas:
      'header image'
      'items items'
      'button button';
    margin: 1.5625rem -0.75rem 0;
    padding: 3.625rem 3rem 3.125rem 1.5rem;
  `)};

  ${media.w.min.px992(css`
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  `)};

  ${({ theme }) =>
    theme.noShadow &&
    css`
      box-shadow: none;
      margin: 0;
      padding: 3.125rem 2.625rem 5rem;
    `}
  ${media.w.min.px768(css`
    padding: 3.125rem 2.625rem 3.75rem;
  `)}
`;

const Header = styled.div`
  align-self: center;
  grid-area: header;
  text-align: center;

  ${media.w.min.px768(css`
    padding-left: 1.3125rem;
    text-align: left;
  `)};
`;

const Image = styled.div`
  grid-area: image;
  margin-bottom: 2.5rem;
  text-align: center;

  ${media.w.min.px768(css`
    margin: 0;
    padding-right: 1.0625rem;
    text-align: right;
  `)};
`;

const ItemLink = styled.div`
  color: ${colors.blue07};
  grid-area: link;
`;

const ItemNumber = styled.div`
  color: ${colors.pfp3};
  font-family: ${typography.fontFamilyRoboto};
  font-size: 3.5rem;
  font-weight: 700;
  grid-area: number;
  line-height: 1;
  padding: 0 0.75rem;

  ${media.w.min.px768(css`
    padding: 0;
    text-align: right;
  `)};
`;

const ItemText = styled.div`
  font-family: ${typography.fontFamilyHeaders};
  font-size: 1rem;
  grid-area: text;
  line-height: 1.375rem;

  ${media.w.min.px768(css`
    text-align: right;
  `)};
`;

const Item = styled.div`
  align-items: center;
  display: grid;
  margin-bottom: 2.5rem;
  padding: 0 0.75rem;
  position: relative;
  z-index: ${layers.contentBottom};

  ${media.w.min.px768(css`
    display: block;
    margin-bottom: 4.25rem;
    padding: 0 1.125rem;
  `)};

  ${media.w.min.px992(css`
    padding: 0 0.5rem;
  `)};

  ${media.w.min.px1200(css`
    padding: 0 2.8%;
  `)};

  &:nth-child(1) {
    ${media.w.min.px768(css`
      flex: 156 156 auto;
    `)};
  }

  &:nth-child(2) {
    ${media.w.min.px768(css`
      flex: 156 156 auto;
      padding-top: 3.25rem;
    `)};
  }

  &:nth-child(3) {
    ${media.w.min.px768(css`
      flex: 136 136 auto;
      padding-top: 2.375rem;
    `)};
  }

  &:nth-child(4) {
    ${media.w.min.px768(css`
      flex: 112 112 auto;
      padding-top: 4.4375rem;
    `)};
  }

  &:nth-child(5) {
    ${media.w.min.px768(css`
      flex: 143 143 auto;
      padding-top: 2.3125rem;
    `)};
  }

  &:nth-child(odd) {
    grid-template-areas:
      'number text'
      '. link';
    grid-template-columns: 6.875rem auto;

    ${ItemNumber} {
      text-align: right;
    }
  }

  &:nth-child(even) {
    grid-template-areas:
      'text number'
      'link .';
    grid-template-columns: auto 6.875rem;

    ${ItemLink},
    ${ItemText} {
      text-align: right;
    }
  }
`;

const Items = styled.div`
  grid-area: items;
  position: relative;

  ${media.w.min.px768(css`
    display: flex;
    margin: 0 -2.625rem 0 -1.125rem;
    padding-top: 1.375rem;
    ${({ theme }) =>
      theme.noShadow &&
      css`
        margin: 0;
      `};
  `)};

  ${media.w.min.px1200(css`
    display: flex;
    margin: 0 -1.125rem;
    position: relative;
    ${({ theme }) =>
      theme.noShadow &&
      css`
        margin: 0;
      `};
  `)};

  ${({ theme }) =>
    theme.noShadow &&
    css`
      margin: 0;
    `};
`;

const ItemsLine = styled.div`
  background: url('${homepageHowItWorksLine}') no-repeat;
  background-size: 100% 100%;
  height: 4.75rem;
  left: 13%;
  position: absolute;
  right: 1.125rem;
  top: 3.875rem;
  z-index: 5;

  ${media.w.max.px768(css`
    display: none;
  `)};
`;

const StyledSectionTitle = styled(SectionTitle)`
  margin-bottom: 0.9375rem;
`;

const Title = styled.div`
  font-family: ${typography.fontFamilyHeaders};
  font-size: 1.5rem;
  line-height: 1.875rem;
  margin-bottom: 2.5rem;
`;

export const Wrapper = styled.div`
  ${media.w.max.px768(css`
    margin: 0 auto;
    max-width: 22.5rem;
  `)};
`;

interface HowItWorksItem {
  link?: {
    label: string;
    url: string;
  };
  text: string;
}

const items: HowItWorksItem[] = [
  { text: 'Wybierz tematy szkoleń, które Cię interesują' },
  {
    text: 'Weź bezpłatny udział we wszystkich szkoleniach',
  },
  { text: 'Konsultuj swoje wyzwania biznesowe z&nbsp;ekspertami ' },
  { text: 'Odbierz<br />certyfikat PFP' },
  { text: 'Kontynuuj efektywny rozwój swojej kariery' },
];

interface HowItWorksProps {
  noShadow?: boolean | undefined;
}

export const HowItWorks: FC<HowItWorksProps & StyleProps> = ({ className, noShadow }) => (
  <ThemeProvider theme={{ noShadow }}>
    <Wrapper className={className}>
      <Container>
        <Image>
          <HomepageHowItWorksImage />
        </Image>

        <Header>
          <StyledSectionTitle>Dlaczego warto</StyledSectionTitle>
          <Title>Jak działa platforma szkoleń PFP?</Title>
        </Header>

        <Items>
          {items.map((item, index) => (
            <Item key={index}>
              <ItemNumber>{index + 1}.</ItemNumber>

              <ItemText>
                <Markdown text={item.text} />
              </ItemText>

              {item.link && (
                <ItemLink>
                  <Button as={GatsbyLink} decoration="underline" kind="link" to={item.link.url}>
                    {item.link.label}
                  </Button>
                </ItemLink>
              )}
            </Item>
          ))}

          <ItemsLine />
        </Items>

        <ButtonContainer>
          <Button as={GatsbyLink} to={SearchRoute}>
            Zapisz się bezpłatnie
          </Button>
        </ButtonContainer>
      </Container>
    </Wrapper>
  </ThemeProvider>
);
