import { RootState } from 'src/state/reducer';
import { createSelector } from 'reselect';
import { AuthState } from 'src/state/auth/authReducer';

export const authStateSelector = (state: RootState) => state.auth;

export const redirectUrlSelector = createSelector(
  authStateSelector,
  (state: AuthState) => state.redirectUrl,
);
