import styled from 'styled-components';
import { colors } from 'src/config/colors';

export const SectionTitle = styled.div`
  color: ${colors.pfp3bis};
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1rem;
  letter-spacing: 0.15em;
  margin-bottom: 0.625rem;
  text-transform: uppercase;
`;
